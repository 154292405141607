import React,{Component} from 'react';
import ProductItem from './productItem';
import ProductsArray from '../data/products.config.json';

class Products extends Component{

	constructor(props){
		super(props);
		this.aProducts = ProductsArray.map((ele) => {
			return(
				<ProductItem
					key= {ele.id}
					content = {ele}
				/>
			)
		});
	}

	render(){
		return(
			<section className="my-5 py-5 products">
				<h3 className="heading text-center text-uppercase">Our Products</h3>
				<p className="heading-bottom text-center font-italic mb-5">One stop for Engineering solutions</p>
				<div className="container">
					<div className="row">
						{this.aProducts}
					</div>
				</div>
			</section>
		);
	}
}

export default Products;
