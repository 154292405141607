import React from 'react';

const Client = (props) => {


	return (
		<div className="col-lg-4 col-md-6 mb-5 Clients">
			<span>{props.clientName}</span>
		</div>
	);
}

export default Client;