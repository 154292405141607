import React from 'react';

const Footer = ()=> {

	return(
		<footer>
			<div className="footer-holder"> 
				<div className="container"> 
					<div className="row py-5"> 
						<div className="col-sm-12 col-lg-6 col-md-6 mb-5"> 
							<h4 className="sub_service_agileits text-uppercase mb-2">
								About Us
							</h4>
							<p className="mb-2">
								Based in Mahad, Raigad. We aim to cater every needs of engineering and project management be it be project 
								erection work, maintainace work, pumping solutions, sealing solution, valves, coated nut bolts and equipments etc. 
							</p>
						</div>
						<div className="col-sm-12 col-lg-6 col-md-6 mb-5"> 
							<h4 className="sub_service_agileits text-uppercase mb-2">
								Regd. Office :
							</h4>
							<address>
								<p>
									<i className="fas fa-map-marker-alt" aria-hidden="true"></i>
									Aquarius Corporation, B-703, White House,Kakartale, Mahad-Raigad, 402301
								</p>
								<p><i className="fas fa-phone" aria-hidden="true"></i>7773904913, 9823782825</p>
								<p><i className="fas fa-envelope" aria-hidden="true"></i> <a href="mailto:sales@aquariuscorporation.com"> </a>sales@aquariuscorporation.com, aquariuscorporation18@gmail.com</p>
							</address>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;