import React,{Component} from 'react';
import HomeItem from './homeItem';
import Client from './client';
import Crousel from './crousel';
import HomeConfig from '../data/home.config.json';
/*import logo from '../images/welcome.jpg';*/
class Home extends Component{

	constructor(props){
		super(props);
		this.aHomeItems = HomeConfig.homeItems.map((ele) => {
			return (
				<HomeItem 
					key = {ele.id}
					homeItemContent = {ele}
				/>
			);
		});

		this.aClients = HomeConfig.clients.map((ele) => {
			return (
				<Client 
					key = {ele.id}
					clientName = {ele.name}
				/>
			);
		});

	}

	render(){
		return(
			<section className="home py-5 my-lg-2">
				<div className="container">
					<h3 className="heading text-center text-uppercase">Welcome to Aquarius</h3>
					<p className="heading-bottom text-center font-italic mb-5">One stop for Engineering solutions</p>
					<div className="row">
						<div className="col-sm-12">
							<Crousel />
						</div>
						<div className="mt-5 py-5 px-4 col-lg-12 agileits_banner_bottom_left">
							<div className="row">
								{this.aHomeItems}
							</div>
						</div>
					</div>
				</div>
				<div className="container my-5">
					<h3 className="heading text-center text-uppercase">Our Clients</h3>
					<div className="row my-5">
						{this.aClients}
					</div>
				</div>
			</section>
		);
	}
}

export default Home;
