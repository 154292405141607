import React from 'react';
/*import Map from './map';*/

const Address = (props)=> {

	const sCoName = props.payload.name,
			sAddL1 = props.payload.line1,
			sAddL2 = props.payload.line2,
			sAddL3 = props.payload.line3,
			sPhone = props.payload.phone,
			sEmail = props.payload.email;/*,
			oCoord = props.payload.coordinates;*/


	return(
		<div className="col-lg-6 col-md-12 col-sm-12 text-center">
			<address className="py-5 contact-left">
				<p><i className="fas fa-map-marker-alt" aria-hidden="true"></i> {sCoName}</p>
				<p>{sAddL1}</p>
				<p>{sAddL2}</p>
				<p>{sAddL3}</p>
				<p><i className="fas fa-phone" aria-hidden="true"></i>{sPhone}</p>
				<p><i className="fas fa-envelope" aria-hidden="true"></i> <a href={"mailto:"+sEmail}>{sEmail}</a></p>
			</address>
		</div>
	);
}

export default Address;