import React,{Component} from 'react';
import ReactDOM from 'react-dom';
import Header from './components/header';
import Footer from './components/footer';
import {BrowserRouter,Route , Switch} from 'react-router-dom';
import Services from './components/services';
import ContactUs from './components/contactus';
import Home from './components/home';
import Products from './components/products';
import Loader from './components/loader';
import './style/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component{

	constructor(props){
		super(props);
		this.state = {isLoading : true};
	}

	componentDidMount(){
		const oScope = this;
		setTimeout(function(){
			oScope.setState({isLoading:false});
		},1000);
	}

	render(){
		return(
			<BrowserRouter basename="/">
				<div className='main-wrapper'>
					<Loader isLoading={this.state.isLoading}/>
					<Header />
					<Switch>

						<Route path="/products" component={ Products }  />
						<Route path="/services" component={ Services }  />
						<Route path="/contactus" component={ ContactUs } />
						<Route path="/" component={ Home } strict />
					</Switch>
					<Footer />
				</div>
			</BrowserRouter>
		);

	}
}

ReactDOM.render(<App />, document.getElementById('root'));

