import React from 'react';

const ServiceItem = (props) => {

	if(!props.serviceContent){
		return (
			<div>Loading.... </div>
		);
	}

	const sIcon = props.serviceContent.icon,
			sHeading = props.serviceContent.heading,
			sContent = props.serviceContent.content;

			

	return(	
		<div className="col-lg-4 col-md-6 agileits_banner_bottom_left mb-5">
			<div className="agileinfo_banner_bottom_pos">
				<div className="row w3_agileits_banner_bottom_pos_grid">
					<div className="col-3 wthree_banner_bottom_grid_left">
						<div className="agile_banner_bottom_grid_left_grid hvr-radial-out text-center">
							<i className={sIcon} aria-hidden="true"></i>
						</div>
					</div>
					<div className="col-9 wthree_banner_bottom_grid_right">
						<h4 className="sub_service_agileits text-uppercase mb-2">{sHeading}</h4>
						<p className="mb-2">{sContent}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceItem;