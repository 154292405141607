import React from 'react';
import ServicesArray from '../data/services.config.json';
import ServiceItem from './serviceItem';

const Services = (props) =>{

	const aServices = ServicesArray.map((service) => {

		return (
			<ServiceItem 
				serviceContent = {service}
				key={service.id}
			/>
		);
	});


	return(
		<section className="services py-5 my-lg-5">
			<h3 className="heading text-center text-uppercase">Our Services</h3>
			<p className="heading-bottom text-center font-italic mb-5">One stop for Engineering solutions</p>
			<div className="container">
				<div className="row">
					{aServices}
				</div>
			</div>
		</section>
	)
}

export default Services;
