import React,{Component} from 'react';
import { Link } from 'react-router-dom';

class Header extends Component{


	render(){
		return(
			<header className="header-section">
				<nav className="navbar">
					<span className="navbar-text brand-text">
				      	Aquarius Corporation
				    </span>
					<ul className="nav justify-content-end">
					  	<li className="nav-item">
					    	<Link className="nav-link active" to="/">Home</Link>
					  	</li>
					  	<li className="nav-item">
					    	<Link className="nav-link" to="/products">Products</Link>
					  	</li>
					  	<li className="nav-item">
					    	<Link className="nav-link" to="/services">Services</Link>
					  	</li>
					  	<li className="nav-item">
					    	<Link className="nav-link" to="/contactus">Contact us</Link>
					  	</li>
					</ul> 			
				</nav>
			</header>
		);
	}
}

export default Header;