import React from 'react';

const HomeItem = (props) => {

	const sIcon = props.homeItemContent.icon,
			sHeading = props.homeItemContent.heading,
			sContent = props.homeItemContent.content;

			

	return(	
		<div className="agileinfo_banner_bottom_pos col-sm-12 col-lg-6">
			<div className="row w3_agileits_banner_bottom_pos_grid mb-5">
				<div className="col-3 wthree_banner_bottom_grid_left">
					<div className="agile_banner_bottom_grid_left_grid hvr-radial-out text-center">
						<i className={sIcon} aria-hidden="true"></i>
					</div>
				</div>
				<div className="col-9 wthree_banner_bottom_grid_right">
					<h4 className="sub_service_agileits text-uppercase mb-2">{sHeading}</h4>
					<p className="mb-2">{sContent}</p>
				</div>
			</div>
		</div>
	);
}

export default HomeItem;