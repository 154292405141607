import React from 'react';

const ProductItem = (props) => {


	const sName = props.content.name,
		sImageUrl = props.content.image,
		sFeaturs = props.content.features,
		aSpecifications = props.content.specifications.map((spec) => {
			return (
				<li key={spec} > {spec} </li>
			);
		});


	return (
		<div className="col-lg-4 col-md-6 col-12 my-5 products-col">
			<div className="row mx-1">
				<div className="col-12 product-heading text-center my-3">
					<h2 className="py-3">{sName}</h2>	
				</div>
				<div className="col-12 product-image text-center">
					<img src={require(`../images/${sImageUrl}`)} alt="" />	
				</div>
				<div className="col-12 product-specifications my-3">
					<h3>Specifications : </h3>
					<ul>{aSpecifications}</ul>
				</div>
				{
					sFeaturs ? 
					<div className="col-12 product-features my-3">
						<h3>Silent Features : </h3>
						<span>{sFeaturs}</span>
					</div>:
					null

				}
			</div>
		</div>
	);
}

export default ProductItem;