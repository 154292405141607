import React,{Component} from 'react';
import Address from './Address';
import AdressArray from '../data/contactus.config.json';

class ContactUs extends Component{

	constructor(props){
		super(props);
		this.state = {
			name:'',
			email:'',
			mobile:'',
			address:'',
			message:'',
			bsubmit:false
		};
		this.adresses = AdressArray.map((add)=>{
			return(
				<Address 
					payload ={add}
					key={add.key}
				/>
			);
		});
		
	}

	stateInitializer = ()=>{
		return {
			name:'',
			email:'',
			mobile:'',
			address:'',
			message:'',
			bsubmit:true
		}
	}

	handleInputChange = (e) =>{
		let {name,value} = e.target;
		this.setState({[name]:value})
	}

	handleFormSubmit = (e) =>{
		e.preventDefault();
		e.stopPropagation();

		let content = 'Hi, <br/> <b>Customer Info : </b> <br/> Name : '+this.state.name+'<br/>'+
						'Mobile : '+this.state.mobile +'<br/>'+
						'Email : '+this.state.email +'<br/>'+
						'Message : '+this.state.message+'<br/>';

		let oForm = {
			// email:this.state.email,
			// name:this.state.name,
			// mobile:this.state.mobile,
			// message:this.state.message,
			content:content
		}

		fetch('http://aquariuscorporation.com/services/contact_mail.php', {
		  	method: 'POST',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
		  	},
		  	body: JSON.stringify(oForm)
		});


		this.setState(this.stateInitializer());
	}

	render(){
		return(
			<section className="container contactus py-5 my-5">
				<h3 className="heading text-center text-uppercase">Our Locations</h3>
				<p className="heading-bottom text-center font-italic mb-5">One stop for Engineering solutions</p>
				<div className="row contact-row">
					{this.adresses}
				</div>
				<h3 className="heading text-center">Write to Us</h3>
				
			    <div className="row contact-us-row">
				    <div className="col-md-12">
				    	{	this.state.bsubmit && 
						    <div className="alert alert-success my-5 text-center" role="alert">
							  	Thank you!! Our team will get back to you shortly!!
							</div>
				    	}
				    	<form className="my-5" onSubmit={this.handleFormSubmit} >
				    		<div className="form-row">
							    <div className="form-group col-md-6">
							      	<label className="control-label" htmlFor="customer_name">Name</label>
							      	<input type="text" className="form-control" id="customer_name" placeholder="Name" required
							      		name="name"
							      		value={this.state.name}
							      		onChange = {this.handleInputChange}
							      	/>
							    </div>

							    <div className="form-group col-md-6">
							      	<label htmlFor="customer_email">Email</label>
							      	<input type="email" className="form-control" id="customer_email" placeholder="Email"
							      		name="email"
							      		value={this.state.email}
							      		onChange = {this.handleInputChange}
				      				/>
							    </div>
						  	</div>
						  	<div className="form-row">
					  		 	<div className="form-group col-md-6">
							      	<label className="control-label" htmlFor="customer_mobile">Mobile</label>
							      	<input type="tel" className="form-control" id="customer_mobile" placeholder="Mobile No." required
							      		name="mobile"
							      		value={this.state.mobile}
							      		onChange = {this.handleInputChange}
							      	/>
							    </div>
					  		 	<div className="form-group col-md-6">
							      	<label htmlFor="customer_address">Address</label>
							      	<input type="text" className="form-control" id="customer_address" placeholder="Address" 
							      		name="address"
							      		value={this.state.address}
							      		onChange = {this.handleInputChange}
							      	/>
							    </div>
						  	</div>
						  	<div className="form-row">
					  		 	<div className="form-group col-md-12">
							      	<label htmlFor="customer_message">Message</label>
							      	<textarea className="form-control" id="customer_message" placeholder="Type your Message"  rows="3"
							      		name="message"
							      		value={this.state.message}
							      		onChange = {this.handleInputChange}
							      	>
							      	</textarea>
							    </div>
						  	</div>
					  	 	<button className="btn btn-primary btn-lg" type="submit">Submit</button>
				    	</form>
				    </div>
			    </div>

			</section>
		);
	}
}

export default ContactUs;
