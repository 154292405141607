import React from 'react';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";
import logo from '../images/welcome.jpg';

const Crousel = () =>{
	const styles = { height: 400, width: "100%" ,backgroundColor: "#f85a40"};
	return(
		<RBCarousel
              version={4}
              autoplay={true}
              pauseOnVisibility={true}
              slideshowSpeed={2000}
        >
        	<div style={{ ...styles }}>
                <div className="carousel-center">
                  <img
	                  style={{ width: "100%", height: "100%" }}
	                  src={logo}
	                  alt="Aquarius Corporation"
            		/>
                </div>
                <div className="carousel-caption"></div>
          	</div>
          	<div style={{ ...styles }}>
                <div className="carousel-center">
              		<h1 className="heading text-center text-uppercase">PUMPS AND ITS SPARES</h1>	
                </div>
          	</div>
          	<div style={{ ...styles }}>
                <div className="carousel-center">
              		<h1 className="heading text-center text-uppercase">VALVES AND MECHANICAL SEALS</h1>	
                </div>
          	</div>
          	<div style={{ ...styles }}>
                <div className="carousel-center">
              		<h1 className="heading text-center text-uppercase">COATED NUT BOLTS AND EQUIPMENTS</h1>	
                </div>
          	</div>
          	<div style={{ ...styles }}>
                <div className="carousel-center">
              		<h1 className="heading text-center text-uppercase">EPOXY FLOORING & INDUSTRIAL FALSE CEILING</h1>	
                </div>
          	</div>
          	<div style={{ ...styles }}>
                <div className="carousel-center">
              		<h1 className="heading text-center text-uppercase">STORAGE TANKS AND VESSELS(SS, MS, PP, FRP)</h1>	
                </div>
          	</div>
              
        </RBCarousel>
	)
}

export default  Crousel;